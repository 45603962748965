import { useEffect, useState, useCallback } from "react"
import GapAcceptanceBar from "../graphs/GapAcceptanceBar";
import VideoPlayer from "../VideoPlayer";

const GapAcceptance = ({ formattedData, videoObj }) => {
    const [data, setData] = useState([])
    const [hour, setHour] = useState(null)
    const [noData, setNoData] = useState(true)
    const [gapType, setGapType] = useState(null)

    useEffect(() => {
        setNoData(true)

        let allData = []
        for (const key in formattedData) {
            const eventData = formattedData[key];

            if (eventData.eventType.startsWith('gap')) {
                let newData = eventData;
                const videoData = videoObj[eventData.eventType]

                if (videoData) {
                    newData["video_data"] = videoObj[eventData.eventType]
                }
                allData.push(eventData)
                setNoData(false)
            }
        }
        setData(allData)
    }, [formattedData, videoObj])

    const handleBarClick = useCallback((entry, label) => {
        console.log('Clicked Hour:', entry.hour);
        console.log(label)
        setHour(entry.hour);
        setGapType(label)
    }, []);

    const handleBackClick = () => {
        setHour(null)
        setGapType(null)
    }

    return (
        <div>
            {hour && <div>
                {videoObj && Object.keys(videoObj).map((property) => {
                    const hoursData = videoObj[property];
                    if (property.startsWith('gap')) {
                        return (
                            <div key={property}>
                                {Object.keys(hoursData).map((hour_obj) => {
                                    const videoLinks = hoursData[hour_obj];

                                    if (hour === parseInt(hour_obj) - 1) {
                                        return (
                                            <div key={hour_obj}>
                                                {videoLinks.length > 0 && <VideoPlayer videoLinks={videoLinks} backClick={handleBackClick} label={property} hour={hour} />}
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        );
                    }

                })}

            </div>}
            <div>
                {data && !hour && data.map((dataObj, idx) => {

                    return (
                        <div key={idx}>
                            <GapAcceptanceBar label={dataObj.eventType} index={idx} data={dataObj.data} handleBarClick={handleBarClick} />
                        </div>
                    );
                })}
            </div>

            {noData && <div>
                <h3>No data to display.</h3>
            </div>}
        </div>
    );
}

export default GapAcceptance