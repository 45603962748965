import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DatePickerModal from './popups/DatePicker';
import { useEffect, useState } from 'react';

export default function HolisticDatePicker({ primaryDateRange, secondaryDateRange, onPrimaryDateRangeChange, onSecondaryDateRangeChange, onCancel }) {

    const [selectedRow, setSelectedRow] = useState(0)
    const [message, setMessage] = useState(null)

    useEffect(() => {
        setMessage(null)
    }, [selectedRow])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyItems: 'center' }}>
            {/* Row Change */}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingBottom: '10px' }}>
                <Tabs value={selectedRow} onChange={(e, newValue) => setSelectedRow(newValue)}>
                    <Tab label="Primary" sx={{ color: 'white' }} />
                    <Tab label="Secondary" sx={{ color: 'white' }} />
                </Tabs>
            </div>

            <div style={{paddingBottom: `${!message ? '20px' : ''}`}}>
                {selectedRow === 0 && <div>
                    <DatePickerModal dateRangeProp={primaryDateRange} onCancel={onCancel} onSubmit={(dateObj) => { onPrimaryDateRangeChange(dateObj); setMessage("Primary date range changed.") }} />
                </div>}
                {selectedRow === 1 && <div>
                    <DatePickerModal dateRangeProp={secondaryDateRange} onCancel={onCancel} onSubmit={(dateObj) => { onSecondaryDateRangeChange(dateObj); setMessage("Secondary date range changed.") }} />
                </div>}
                <p style={{color: 'var(--neutral-300)'}}>{message}</p>
            </div>
        </div>
    )
}