import { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Legend, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

export default function LongDurationBar(props) {
    const [processedData, setProcessedData] = useState()

    useEffect(() => {
        const processedData = [];
        for (let hour = 6; hour <= 21; hour++) {
            const hourData = props.data.filter(item => item.hour === hour);
            const movementCounts = {
                hour: hour,
                "none": hourData.filter(item => item.movement === "").length,
                "thru": hourData.filter(item => item.movement === "thru").length,
                "left": hourData.filter(item => item.movement === "left").length,
                "right": hourData.filter(item => item.movement === "right").length,
            };
            processedData.push(movementCounts);
        }


        setProcessedData(processedData)
    }, [props.data])


    return (
        <div style={{ width: '100%' }}>
            {processedData &&
                <ResponsiveContainer  width="100%" height={400}>
                    <BarChart width={600} height={600} data={processedData} barSize={10} margin={{ top: 20, bottom: 20 }}>
                        <CartesianGrid vertical={false} horizontal={true} stroke="#999AAD" strokeWidth={0.2}/>
                        <XAxis dataKey="hour" axisLine={false} tickLine={false} label={{ value: 'Hour', position: 'insideBottom', offset: -10 }} />
                        <YAxis label={{value: props?.label, angle: -90, position: 'insideLeft', style: {color: '#999AAD'}}} axisLine={false} tickLine={false} />
                        <Tooltip contentStyle={{color: '#999AAD'}} />
                        {/* <Legend /> */}
                        <Bar dataKey="none" stackId="a" fill="#00A19C" onClick={(e) => props.handleBarClick(e)} cursor={'pointer'} radius={[15, 15, 0, 0]}/>
                        <Bar dataKey="thru" stackId="a" fill="#82ca9d" onClick={(e) => props.handleBarClick(e)} cursor={'pointer'} radius={[15, 15, 0, 0]}/>
                        <Bar dataKey="left" stackId="a" fill="#ffc658" onClick={(e) => props.handleBarClick(e)} cursor={'pointer'} radius={[15, 15, 0, 0]}/>
                        <Bar dataKey="right" stackId="a" fill="#ff7300" onClick={(e) => props.handleBarClick(e)} cursor={'pointer'} radius={[15, 15, 0, 0]}/>
                    </BarChart>
                </ResponsiveContainer>
            }
            {!processedData && <p>Loading...</p>}
        </div>
    )
}
