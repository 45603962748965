import React, { useState } from 'react';
import { useEffect, useRef } from 'react';
import InfoDrawer from '../components/InfoDrawer'
import mapboxgl from 'mapbox-gl'



const Dashboard = (props) => {
  const [agency, setAgency] = useState(props.agency)
  const [intersections, setIntersections] = useState()
  const [selectedIntersection, setSelectedIntersection] = useState()
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [weekends, setWeekends] = useState();
  const clickZoom = 16
  const mapRef = useRef(null)

  useEffect(() => {
    if (!props.agency) {
      // Route back to login or somewhere else
    }
    setAgency(props.agency)

    // TODO: Put in env
    mapboxgl.accessToken = 'pk.eyJ1IjoicmljZXNsaWYiLCJhIjoiY2xscG8wZTl6MDdlODNkcXppcTN2OWs3byJ9.yyOjhxq2q7PUlrebCD4Yfg'
    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/dark-v10',
      center: [-84.393, 33.752],
      zoom: 12,
    });

    mapRef.current = map;

    setIntersections(props.intersections)
    addIntersections(map, props.intersections)
    if(props.intersections?.length){
      let newLat = 0
      let newLong = 0
      props.intersections.forEach(intersection=>{
        newLat = newLat + parseFloat(intersection.lat)
        newLong = newLong + parseFloat(intersection.long)
      })
      newLat = newLat/props.intersections?.length
      newLong = newLong/props.intersections?.length

      mapRef.current.easeTo({
        center: [newLong.toString(), newLat.toString()],
      })

    }

  }, [props.intersections])

  useEffect(() => {

    setStartDate(props?.dateRange?.startDate);
    setEndDate(props?.dateRange?.endDate)
    setWeekends(props?.dateRange?.weekends)
  }, [props.dateRange])

  useEffect(() => {
    if (!props.intersectionSelected) {
      return
    } else {
      const bounds = mapRef.current.getBounds();
      const offsetLong = ((bounds._ne.lng - bounds._sw.lng)/4 )
      const zoomChangeRatio = Math.pow(2, clickZoom) /  Math.pow(2, mapRef.current.getZoom())
      const newLong = parseFloat(props.intersectionSelected.long) + offsetLong / zoomChangeRatio

      toggleDrawer(props.intersectionSelected.id)
      if (mapRef.current !== null) {
        mapRef.current.easeTo({
          center: [newLong.toString(), props.intersectionSelected.lat],
          zoom: clickZoom,
        })
      }      
    }
  }, [props.intersectionSelected])

  const toggleDrawer = (inter) => {
    setSelectedIntersection(inter)
    props.openDrawer();
  };

  const closeDrawer = () => {
    setSelectedIntersection(null)
    props.closeDrawer();
  }

  const addIntersections = (map, data) => {
    if (!data || !Array.isArray(data)) {
      return
    }

    data.forEach((intersection) => {
      const markerElement = document.createElement('div')
      markerElement.className = 'custom-marker'
      markerElement.style.backgroundColor = `rgba(#32D583, 0.5)`
      markerElement.style.borderColor = '#32D583'

      const marker = new mapboxgl.Marker({
        element: markerElement
      })
        .setLngLat([intersection.long, intersection.lat])
        .addTo(map);

      // Attach a click event listener to the marker
      marker.getElement().addEventListener('click', () => {
        props.setIntersectionSelected(intersection)
        console.log(intersection.id, intersection.long, intersection.lat);

        toggleDrawer(intersection.id)
      });
    });
  }

  return (
    <div>
      <InfoDrawer show={props.isDrawerOpen} onClose={closeDrawer} intersection={selectedIntersection} agency={agency}
        startDate={startDate} endDate={endDate} setValue={props.setValue} weekends={weekends} />
      <div id="map" style={{ width: '100%', height: '100vh' }}></div>
    </div>

  );
};

export default Dashboard;
