
function Info() {
  return (
    <svg
      data-name="Layer 1"
      viewBox="0 0 24 24"
      fill="rgb(209 213 219)"
      height="2.5rem"
      width="2.5rem"
    >
      <path d="M12 10a1 1 0 00-1 1v6a1 1 0 002 0v-6a1 1 0 00-1-1zm0-4a1.25 1.25 0 101.25 1.25A1.25 1.25 0 0012 6z" />
    </svg>
  );
}

export default Info;
