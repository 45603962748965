import { useState } from "react";
import { useRef } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Stage, Layer, Circle, Image, Rect, Shape } from 'react-konva';
import { VscOpenPreview } from "react-icons/vsc";

const dataMapping = [
    { id: 0, name: 'center', coordinates: [], color: '#0053A1', common_name: "Center", desc: "Center of intersection." },
    { id: 1, name: 'EB', coordinates: [], color: '#A12500', common_name: "EB", desc: "Eastbound." },
    { id: 2, name: 'WB', coordinates: [], color: '#A10083', common_name: "WB", desc: "Westbound." },
    { id: 3, name: 'NB', coordinates: [], color: '#00A189', common_name: "NB", desc: "Northbound." },
    { id: 4, name: 'SB', coordinates: [], color: '#00A14B', common_name: "SB", desc: "Southbound." },
    { id: 5, name: 'EB_zone', coordinates: [], color: '#A1009B', common_name: "EB Zone", desc: "Eastbound zone." },
    { id: 6, name: 'WB_zone', coordinates: [], color: '#4BA100', common_name: "WB Zone", desc: "Westbound zone." },
    { id: 7, name: 'NB_zone', coordinates: [], color: '#4800A1', common_name: "NB Zone", desc: "Northbound zone." },
    { id: 8, name: 'SB_zone', coordinates: [], color: '#0078A1', common_name: "SB Zone", desc: "Southbound zone." },
    { id: 9, name: 'queue_thru', coordinates: [], color: '#A10096', common_name: "Queue Thru", desc: "Area for target through movement. After stopbar with a long detection area." },
    { id: 10, name: 'queue_left', coordinates: [], color: '#A10000', common_name: "Queue Left", desc: "Area for target left movement. After stopbar with a long detection area." },
    { id: 11, name: 'ped_zone', coordinates: [], color: '#6EA100', common_name: "Ped Zone", desc: "Pedestrian zone. Should be around pedestrian signal." },
    { id: 12, name: 'rlr_thru', coordinates: [], color: '#A12000', common_name: "RLR Thru", desc: "Red light running through. Should be beyond stopbar with small detection area." },
    { id: 13, name: 'rlr_left', coordinates: [], color: '#03A100', common_name: "RLR Left", desc: "Red light running left. Should be beyond stopbar with small detection area." },
    { id: 14, name: 'speed_zone', coordinates: [], color: '#B4B4B4', common_name: "Speed Track Zone", desc: "Rectangle where speed should be tracked." },
    { id: 15, name: 'lane_line', coordinates: [], color: '#B4B4B4', common_name: "Lane Length", desc: "Marks one lane length. Used in speed tracking." }
]

/* 
 * Window that pops up when users have a previous annotation available for an intersection.
 * Close 1:1 representation of what's displayed in Annotation minus writing/ active functionality.
 * Robby Rice
*/
const PreviousAnnotationSelect = ({ id, title, subtitle, config, image, onClick, width, height, intersection }) => {

    const stageRef = useRef(null);

    const [zones, setZones] = useState([])

    const addZones = () => {
        document.getElementById(`preview-dialog-${id}`).showModal()

        const zoneObj = []
        const prevConfig = JSON.parse(config)[intersection]

        for (const [k, v] of Object.entries(prevConfig)) {
            if (Array.isArray(v) && v.length > 1) {
                // Get color
                const dmObj = dataMapping.find((obj) => obj.name === k)
                if(!dmObj) {
                    continue;
                }
                const color = dmObj?.color ?? '#FFF'

                // Get coordinates object [{x: ..., y: ...}]
                const coordsMapping = v.map(points => ({ x: points[0] / 2, y: points[1] / 2 }));
                zoneObj.push({ type: dmObj.name, color: color, coordinates: coordsMapping })
            }
        }
        setZones(zoneObj)
    }

    return (
        <div key={id} style={{
            'display': 'flex', 'flexDirection': 'row', 'width': '100', 'padding': '8px', 'borderRadius': '5px',
            'border': '1px gray solid', 'alignItems': 'center', 'cursor': 'pointer'
        }}>
            <div style={{ 'flexGrow': 1 }} onClick={() => onClick()}>
                <h3>{title}</h3>
                <p style={{ 'color': 'gray' }}>{subtitle}</p>
            </div>
            <div style={{ 'display': 'flex', 'flexDirection': 'row', 'alignItems': 'center' }}>
                <VscOpenPreview onClick={() => addZones()} color='white' size={26} />
                <AiOutlineArrowRight size={26} color='white' onClick={() => onClick()} />
            </div>

            <dialog id={`preview-dialog-${id}`}>
                <div style={{ 'display': 'flex', 'flexDirection': 'column' }}>
                    <div style={{ 'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'end' }}>
                        <button style={{ 'marginBottom': '10px', 'padding': '3px', 'marginRight': '5px' }}
                            onClick={() => { document.getElementById(`preview-dialog-${id}`).close() }}>x</button>
                    </div>
                    <div>
                        {image && width && height && <Stage
                            width={width / 2} height={height / 2}
                            ref={stageRef}
                        >
                            <Layer>
                                <Rect height={width / 2} width={height / 2} fill="#fff" />
                                <Image height={height / 2} width={width / 2} image={image} />

                                {zones && zones.map((zone, index) => {
                                    const { type, coordinates, color } = zone;

                                    const shapeProps = {
                                        sceneFunc: (context, shape) => {
                                            context.beginPath();
                                            context.moveTo(coordinates[0].x, coordinates[0].y);

                                            coordinates.forEach((coord, i) => {
                                                if (i > 0) {
                                                    context.lineTo(coord.x, coord.y);
                                                }
                                            });

                                            context.closePath();
                                            context.fillStrokeShape(shape);
                                        },
                                        fill: color,
                                        opacity: 0.5,
                                        key: index,
                                        onContextMenu: (e) => {
                                            e.evt.preventDefault();
                                        }
                                    };

                                    const outlineProps = {
                                        sceneFunc: (context, shape) => {
                                            context.beginPath();
                                            context.moveTo(coordinates[0].x, coordinates[0].y);

                                            coordinates.forEach((coord, i) => {
                                                if (i > 0) {
                                                    context.lineTo(coord.x, coord.y);
                                                }
                                            });

                                            context.closePath();
                                            context.strokeShape(shape);
                                        },
                                        stroke: color,
                                        strokeWidth: 3,
                                        opacity: 1,
                                        key: `outline-${index}`
                                    };

                                    const circles = coordinates.map((coord, i) => (
                                        <Circle
                                            key={`circle-${i}`}
                                            x={coord.x}
                                            y={coord.y}
                                            radius={4}
                                            fill={color}
                                        />
                                    ));

                                    return <>
                                        <Shape {...shapeProps} />
                                        <Shape {...outlineProps} />
                                        {circles}
                                    </>;
                                })}
                            </Layer>
                        </Stage>}
                    </div>
                </div>

            </dialog>
        </div>

    )
}

export default PreviousAnnotationSelect
