import { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Legend, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

export default function SplitFailureBar(props) {
    const [processedData, setProcessedData] = useState()

    useEffect(() => {
        // Initialize counts for all hours from 6 to 21
        const hourCounts = {};
        for (let hour = 6; hour <= 21; hour++) {
            hourCounts[hour] = 0;
        }

        // Count the occurrences of each hour in the data
        props.data.forEach((item) => {
            const hour = item.hour;
            hourCounts[hour]++;
        });

        // Convert the grouped data to an array of objects with 'hour' and 'count' properties
        const processedData = Object.entries(hourCounts).map(([hour, count]) => ({
            hour: Number(hour),
            count,
        }));

        // Sort the data by hour in ascending order
        processedData.sort((a, b) => a.hour - b.hour);

        setProcessedData(processedData)
    }, [props.data])

    return (
        <div>
            {processedData &&
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart width={600} height={400} data={processedData} barSize={10} margin={{ top: 20, bottom: 20 }}>
                        <CartesianGrid vertical={false} horizontal={true} stroke="#999AAD" strokeWidth={0.2} />
                        <XAxis dataKey="hour" axisLine={false} tickLine={false} label={{ value: 'Hour', position: 'insideBottom', offset: -10 }} />
                        <YAxis label={{ value: props?.label, angle: -90, position: 'insideLeft', style: { color: '#999AAD' } }} axisLine={false} tickLine={false} />
                        <Tooltip contentStyle={{ color: '#999AAD' }} />
                        {/* <Legend /> */}
                        <Bar dataKey="count" fill="#00A19C" onClick={(e) => props.handleBarClick(e)} cursor={'pointer'} radius={[15, 15, 0, 0]} />
                    </BarChart>
                </ResponsiveContainer>}
            {!processedData && <p>Loading...</p>}
        </div>
    )
}
