import React, { useState, useContext } from 'react';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'
import UserPool from './UserPool';
import Tooltip from '@mui/material/Tooltip';
import { AuthenticationContext } from './Authentication';
import { Link, Navigate } from "react-router-dom";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import './auth.css'

/* 
 * Login component. Displayed at /login. Refer to Authentication.js for imported helper methods.
 * 
 * Robby Rice
*/
const Login = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState();

    // Note: These two state variables are used both for a new password (new user) and resetting password
    const [userNewPass, setUserNewPass] = useState()
    const [userNewPassConfirm, setUserNewPassConfirm] = useState()

    const [resetEmail, setResetEmail] = useState()
    const [successData, setSuccessData] = useState(null)
    const [verificationCode, setVerificaionCode] = useState()


    // login, newPass, forgotPass, resetPass, where forgotPass is requested a pass change (need email input), and resetPass is inputting new password.
    const [authState, setAuthState] = useState('login')

    const { authenticate, resetPassRequest, resetPassword } = useContext(AuthenticationContext)

    const handleLogin = () => {
        setMessage('Signing in...')
        authenticate(email, password)
            .then((data) => {
                setMessage('Logged in.')
                setSuccessData(data)
                props.onAuthed(data)
            })
            .catch((err) => {
                // Set message based on error
                switch (err.name) {
                    case "NotAuthorizedException":
                        setMessage("Incorrect username or password.")
                        break;
                    case "InternalErrorException":
                        setMessage("Internal error. Try again later.")
                        break;
                    case "TooManyRequestsException":
                        setMessage("Too many requests. Try again later.")
                        break;
                    default:
                        setMessage('Couldn\'t log in.')
                        break;
                }
                if (err?.new_pass) {
                    setAuthState('newPass')
                    console.log(err.new_pass)
                    setMessage('New password required.')
                }
            })
    }

    const handleNewPass = () => {
        if (userNewPass !== userNewPassConfirm) {
            setMessage("Passwords do not match.");
            return
        }

        // Check if password meets requirements
        if (!confirmPassRequirements(userNewPass)) {
            setMessage("Password does not meet the requirements.");
            return;
        }

        // Set password in AWS Congnito
        // TODO: Make this secure - we should have email, but that doesn't mean we always will. assert(!null)

        const user = new CognitoUser({
            Username: email,
            Pool: UserPool
        })

        const authDetails = new AuthenticationDetails({
            Username: email,
            Password: password
        })

        user.authenticateUser(authDetails, {
            onSuccess: (data) => {
                console.log('success auth in handleNewPass')
            },
            onFailure: (err) => {
                console.error('error in user.authenticateUser for handleNewPass: ', err)
            },
            newPasswordRequired: (data) => {
                console.log('Needs new pass: ', data)
                delete data.email_verified
                delete data.email
                user.completeNewPasswordChallenge(userNewPass, data, {
                    onSuccess: (data) => {
                        console.log('success:', data)
                        setMessage('Password changed.')
                        setAuthState('login')
                        props.onAuthed(data)
                    },
                    onFailure: (err) => {
                        console.error('error', err)
                    }
                })
            }
        })

    }

    const confirmPassRequirements = (pass) => {
        const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;

        return regex.test(pass)
    }

    const handleResetPass = () => {
        resetPassRequest(email)
            .then((data) => {
                console.log('success', data)
                setMessage('Email sent.')
                setAuthState('resetPass')
            })
            .catch((data) => {
                console.log('err', data)
                setMessage('Couldn\'t send email.')
            })
    }

    const handlePasswordReset = () => {
        if (userNewPass !== userNewPassConfirm) {
            setMessage("Passwords do not match.");
            return
        }

        // Check if password meets requirements
        if (!confirmPassRequirements(userNewPass)) {
            console.log(userNewPass)
            setMessage("Password does not meet the requirements.");
            return;
        }

        if (!verificationCode) {
            setMessage("No verification code entered.")
            return;
        }

        // TODO: More verification code verification

        resetPassword(resetEmail, verificationCode, userNewPass)
            .then((data) => {
                setMessage(`Password for ${resetEmail} changed.`);
                setAuthState('login');
            })
            .catch((data) => {
                console.log("failed pass check", data);
                // TODO: More robust messaging.
                setMessage("Couldn't reset password.");
            })
    }

    if (successData) {
        return <Navigate to={`/`} />
    } else {
        return (
            <div style={{ display: 'flex', height: '100vh' }}>
                <div
                    style={{
                        flex: '1',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {authState === 'login' && <form
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',

                        }}
                    >
                        <label className='login-label' for='login-email'>Email</label>
                        <input
                            type="email"
                            id='login-email'
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={{ marginBottom: '10px' }}
                            className='login-field'
                        />
                        <label className='login-label' for='login-password'>Password</label>
                        <input
                            type="password"
                            id='login-password'
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            style={{ marginBottom: '10px' }}
                            className='login-field'
                        />

                        <p style={{ color: 'black', marginBottom: '5px' }}>{message}</p>
                        <button className='login-button' type="button" onClick={handleLogin}>
                            Log In
                        </button>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '4px' }}>
                            <p style={{ cursor: 'pointer', color: 'black', fontSize: '14px' }} onClick={() => {
                                setAuthState('forgotPass');
                                setMessage('Email will send with a verification code.')
                            }}>Forgot Password?</p>
                        </div>
                    </form>}
                    {authState === 'newPass' &&
                        <Tooltip title={<div><h4>Password Requirements</h4><ul><li>8 Characters</li><li>1 Uppercase</li><li>1 Number</li><li>1 Special Character</li></ul></div>} placement="right">
                            <form style={{ display: 'flex', flexDirection: 'column', width: '50%', }}>
                                <label className='login-label' for='new-pass'>New Password</label>
                                <input
                                    type="password"
                                    id='new-pass'
                                    placeholder="NewPass"
                                    value={userNewPass}
                                    onChange={(e) => setUserNewPass(e.target.value)}
                                    style={{ marginBottom: '10px' }}
                                    className='login-field'
                                />
                                <label className='login-label' for='new-pass-confirm'>Confirm Password</label>
                                <input
                                    type="password"
                                    id='new-pass-confirm'
                                    placeholder="NewPass"
                                    value={userNewPassConfirm}
                                    onChange={(e) => setUserNewPassConfirm(e.target.value)}
                                    style={{ marginBottom: '10px' }}
                                    className='login-field'
                                />

                                <p style={{ color: 'black', marginBottom: '5px' }}>{message}</p>
                                <button className='login-button' type="button" onClick={handleNewPass}>
                                    Set New Password
                                </button>
                            </form>
                        </Tooltip>}

                    {authState === 'forgotPass' &&
                        <form style={{ display: 'flex', flexDirection: 'column', width: '50%', }}>
                            <label className='login-label' for='reset-email'>Email</label>
                            <input
                                type="email"
                                id='reset-email'
                                placeholder="Email"
                                value={resetEmail}
                                onChange={(e) => setResetEmail(e.target.value)}
                                style={{ marginBottom: '10px' }}
                                className='login-field'
                            />

                            <p style={{ color: 'black', marginBottom: '5px' }}>{message}</p>
                            <button className='login-button' type="button" onClick={handleResetPass}>
                                Send Reset Email
                            </button>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '4px' }}>
                                <p style={{ cursor: 'pointer', color: 'black', fontSize: '14px' }} onClick={() => {
                                    setAuthState('login');
                                    setMessage('')
                                }}>Back to Login</p>
                            </div>
                        </form>}

                    {authState === 'resetPass' &&
                        <form style={{ display: 'flex', flexDirection: 'column', width: '50%', }}>
                            <label className='login-label' for='reset-email'>Email</label>
                            <input
                                type="email"
                                id='reset-email'
                                placeholder="Email"
                                value={resetEmail}
                                onChange={(e) => setResetEmail(e.target.value)}
                                style={{ marginBottom: '10px' }}
                                className='login-field'
                            />

                            <label className='login-label' for='verif-code'>Verification Code</label>
                            <input
                                type="text"
                                id='verif-code'
                                placeholder="123456"
                                value={verificationCode}
                                onChange={(e) => setVerificaionCode(e.target.value)}
                                style={{ marginBottom: '10px' }}
                                className='login-field'
                            />

                            <Tooltip title={<div><h4>Password Requirements</h4><ul><li>8 Characters</li><li>1 Uppercase</li><li>1 Number</li><li>1 Special Character</li></ul></div>} placement="right">
                                <div style={{ display: 'inherit', flexDirection: 'inherit' }}>
                                    <label className='login-label' for='reset-pass'>Password</label>
                                    <input
                                        type="password"
                                        id='reset-pass'
                                        placeholder="Pass"
                                        value={userNewPass}
                                        onChange={(e) => setUserNewPass(e.target.value)}
                                        style={{ marginBottom: '10px' }}
                                        className='login-field'
                                    />

                                    <label className='login-label' for='reset-pass-confirm'>Confirm Password</label>
                                    <input
                                        type="password"
                                        id='reset-pass-confirm'
                                        placeholder="Pass"
                                        value={userNewPassConfirm}
                                        onChange={(e) => setUserNewPassConfirm(e.target.value)}
                                        style={{ marginBottom: '10px' }}
                                        className='login-field'
                                    />
                                </div>

                            </Tooltip>

                            <p style={{ color: 'black', marginBottom: '5px' }}>{message}</p>
                            <button className='login-button' type="button" onClick={handlePasswordReset}>
                                Reset Password
                            </button>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '4px' }}>
                                <p style={{ cursor: 'pointer', color: 'black', fontSize: '14px' }} onClick={() => {
                                    setAuthState('login');
                                    setMessage('')
                                }}>Back to Login</p>
                            </div>
                        </form>}

                </div>
                <div style={{
                    flex: '1', backgroundColor: 'rgba(51, 180, 176, 1)', justifyContent: 'center', flexGrow: '1',
                    alignItems: 'center', textAlign: 'center', display: 'flex', flexDirection: 'column'
                }}>
                    <div style={{ position: 'absolute', right: '20%', top: '2%' }}>
                        <img style={{}} src='./etalyc.svg' alt='Etalyc logo ' />
                    </div>
                    <h1>HyperSafe</h1>
                    <p>Safer streets, safer city.</p>
                </div>
                <Link to='https://hyperflow.etalyc.com/login'>
                    <div style={{ position: 'absolute', bottom: '0', right: '17%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <p style={{ color: 'var(--neutral-900)', paddingBottom: '2px', fontSize: '12px' }}>Meant to go to HyperFlow?</p>
                        <ArrowOutwardIcon style={{ color: 'var(--neutral-900)' }} />
                    </div>
                </Link>
            </div>
        )
    };
};

export default Login;
