import React, { useState, useEffect } from 'react'
import NotifCard from "./NotifCard"
import { IoChevronUpOutline, IoChevronDownOutline } from "react-icons/io5";

const LogList = ({ inboxNotifications, setInboxNotifications, handleNotifRedirect, collapseAll, expandAll, setCollapseAll, setExpandAll }) => {
    const [collapsedDates, setCollapsedDates] = useState([]);

    const toggleCollapse = (date) => {
        setCollapsedDates((prev) => {
        if (prev.includes(date)) {
            return prev.filter((collapsedDate) => collapsedDate !== date);
        } else {
            return [...prev, date];
        }
        });
    };

    const groupedByDateObj = inboxNotifications.reduce((result, obj) => {
        const date = obj.date.S.split(' ')[0]; // Extracting the date part
        if (!result[date]) {
          result[date] = [];
        }
        result[date].push(obj);
        return result;
      }, {});
      
      const groupedByDateArr = Object.values(groupedByDateObj);

      const currentDate = new Date();
      const formattedCurrentDate = `${currentDate.getFullYear()}-${padZero(currentDate.getMonth() + 1)}-${padZero(currentDate.getDate())}`;
      
      const yesterday = new Date(currentDate);
      yesterday.setDate(currentDate.getDate() - 1);
      const formattedYesterday = `${yesterday.getFullYear()}-${padZero(yesterday.getMonth() + 1)}-${padZero(yesterday.getDate())}`;
      
      function padZero(num) {
        return num < 10 ? `0${num}` : num;
      }

      function calculateTimeAgo(dateString) {
        const currentDate = new Date();
        const utcDate = new Date(dateString + ' UTC'); // Append 'UTC' to the string to ensure parsing as UTC
        const localDate = new Date(utcDate.toLocaleString());
        const timeDifference = currentDate - localDate;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
    
        if (seconds < 60) {
            return `${seconds}s ago`;
        } else if (minutes < 60) {
            return `${minutes}m ago`;
        } else {
            return `${hours}h ago`;
        }
    }
      

    useEffect(() => {
        if (collapseAll) {
          setCollapsedDates(groupedByDateArr.map((dayLogs) => dayLogs[0].date.S.split(" ")[0]));
        } else if (expandAll) {
          setCollapsedDates([]);
        }
      }, [collapseAll, expandAll])

    useEffect(()=>{
        setExpandAll(false)
        setCollapseAll(false)
    },[groupedByDateArr])


    return (
    <div style={{paddingBottom:'20px'}}>      
        {Array.isArray(inboxNotifications) && groupedByDateArr.map((dayLogs, index) => {
                const formattedDate = dayLogs[0].date?.S.split(" ")[0];
                const isCollapsed = collapsedDates.includes(formattedDate);

                let displayText;
                
                if (formattedDate === formattedCurrentDate) {
                    displayText = 'Today';  
                } else if (formattedDate === formattedYesterday) {
                    displayText = 'Yesterday';
                } else {
                    const dateParts = formattedDate.split('-');
                    displayText = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
                }
                
                
                
                return(
                    <div key={index} style={{display: 'flex', flexDirection: 'column', alignItems:'left'}}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <h1 style={{ fontWeight: '500', fontSize: '18px', marginBottom: '10px', marginTop: '20px' }}>{displayText}</h1>
                            { isCollapsed ? 
                                <IoChevronUpOutline 
                                    onClick={() => toggleCollapse(formattedDate)} 
                                    style={{ marginTop: '10px'}} 
                                    className='collapseBtn' 
                                    size={20} 
                                    color={'white'} 
                                /> 
                            :     
                                <IoChevronDownOutline 
                                    onClick={() => toggleCollapse(formattedDate)} 
                                    style={{ marginTop: '10px'}}
                                    className='collapseBtn' 
                                    size={20} 
                                    color={'white'}
                                /> 
                            }
                        </div>
                        { !isCollapsed && (
                            <div style={{display: 'flex', flexDirection: 'column', alignItems:'left', borderLeft: '3px solid #63b3ed', paddingLeft: '20px', gap: '20px'}}>
                                {dayLogs.map((notif, index)=>{ 

                                        let date;

                                        if (displayText == "Today"){
                                            date = calculateTimeAgo(notif.date?.S.split(" ")[0].split('-').join("/") + " " + notif.date?.S.split(" ")[1])
                                        } else {
                                            const utcDate = new Date( notif.date?.S + ' UTC'); // Append 'UTC' to the string to ensure parsing as UTC
                                            const localDate = new Date(utcDate.toLocaleString());

                                            const hours = localDate.getHours();
                                            const minutes = localDate.getMinutes();

                                            date = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
                                        }

                                        return (
                                            <NotifCard handleNotifRedirect={handleNotifRedirect} date={date} notification={notif} inboxNotifications={inboxNotifications} setInboxNotifications={setInboxNotifications} key={notif.id?.S} />
                                        )       
                                    }
                                )}
                            </div>
                        )}
                    </div>
                )
            }
        )}
    </div>
  )
}

export default LogList