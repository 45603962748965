import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Check from './svg/Check';
import Cross from './svg/Cross';
import Info from './svg/Info';

const NotifPopupModal = (props) => {
    const { setActive, notification } = props
    const tags = Boolean(notification.tags?.SS[0])


    const severityColors =  {
        'info': ['rgb(209 213 219)', 'rgb(55 65 81)'],
        'success': ['rgb(134 239 172)', 'rgb(21 128 61)'],
        'warning': ['rgb(252 165 165)', 'rgb(185 28 28)']

    }

  return (
    <div style={styles.popupModalContainer} onClick={(event)=> {
            event.stopPropagation()
            setActive(false)
        }}>
        <div className='expanded-notif-card' style={{... styles.notificationContainer, border:`solid 1px ${severityColors[notification.severity?.S][0]}`}} onClick={(e)=>e.stopPropagation()}>
            <CloseOutlinedIcon style={{position: 'absolute', top: '10px', right: '10px', color: 'white', cursor: 'pointer'}} onClick={()=>setActive(false)} />
            <div style={{marginTop:'20px', height: '4rem', width: '4rem', borderRadius: '9999px', backgroundColor: severityColors[notification.severity?.S][1], display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                {notification.severity?.S == 'success' && <Check />}
                {notification.severity?.S == 'warning' && <Cross />}
                {notification.severity?.S == 'info' && <Info />}
            </div>
            <p style={{textAlign: 'center', fontSize: '22px', fontWeight: '500', lineHeight: '34px'}}>{notification.title?.S}</p>
            <p style={{margin: '10px', textAlign: 'center', color:'gray', fontSize: '16px', lineHeight: '24px'}}>{notification.content?.S}</p>
            <div style={{borderRadius:'9999px', backgroundColor: severityColors[notification.severity?.S][1], padding:'4px', paddingRight:'6px', paddingLeft:'6px'}}>
                <p style={{color: severityColors[notification.severity?.S][0] }}>{notification.severity?.S}</p>
            </div>
            {notification.redirect?.S && 
                <p
                    style={{
                    cursor: 'pointer',
                    flex: '0 0 auto', 
                    maxWidth: 'fit-content', 
                    margin: '0', 
                    textDecoration: `1px underline ${severityColors[notification.severity?.S][0]}`,
                    textUnderlineOffset: '2px' 
                    }}
                    onClick={() => {
                    props.handleNotifRedirect(notification.redirect.S);
                    }}
                >
                    Intersection {notification.redirect.S}
                </p>


            }
            {notification.videoId?.S && <p> VideoID: {notification.videoId.S}</p>}
            {tags && 
                <div style={{display: 'flex', width: '100%', gap:'10px', justifyContent: 'center', alignItems: 'center', margin:'15px'}}>
                    {notification.tags.SS.map((tag, index)=>(
                        <div key={index} style={{borderRadius:'9999px', backgroundColor: 'gray', padding:'4px', paddingRight:'6px', paddingLeft:'6px'}}>
                            <p>{tag}</p>
                        </div>
                    ))}
                </div>
            }
        </div>
    </div>
  )
}

const styles = {
    popupModalContainer: {
        position: 'absolute',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.2)',
        top: '0',
        left: '0'
    },
    notificationContainer: {
        width: '60vh',
        height: '60vh',
        backgroundColor: 'rgb(17 24 39)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '15px',
        gap: '10px',
        borderRadius: '6px',
        position: 'relative'
    },

}

export default NotifPopupModal