import React, { useState } from 'react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';

const VideoPlayer = ({ videoLinks, backClick, label, hour }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const previousVideo = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + videoLinks.length) % videoLinks.length);
  };

  const nextVideo = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % videoLinks.length);
  };

  return (
    <div className="video-player">
      <div style={{ 'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'space-between', 'alignItems': 'center', 'marginBottom': '10px' }}>
        <div style={{ 'display': 'flex', 'flexDirection': 'row', 'alignItems': 'center', 'gap': '10px' }}>
          <button onClick={backClick} className='icon-button'><KeyboardBackspaceIcon style={{width: '20px'}} /></button>
          <p style={{ 'color': '#D0D5DD', 'paddingBottom': '3px', 'fontWeight': '500', 'letterSpacing': '0.05px' }}>{label} - {hour}</p>
        </div>
        <div className="video-navigation">
          <button onClick={previousVideo} className='icon-button'><FirstPageIcon /></button>
          <p style={{ 'color': '#D0D5DD', 'paddingBottom': '2px', 'fontWeight': '500', 'fontSize': '14px' }}>Video {currentIndex + 1}/{videoLinks.length}</p>
          <button onClick={nextVideo} className='icon-button'><LastPageIcon /></button>
        </div>
      </div>
      {videoLinks.length > 0 && (
        <>
          <video controls autoPlay key={currentIndex} height={'auto'} width={'100%'}>
            <source src={videoLinks[currentIndex]} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </>
      )}
    </div>
  );
};

export default VideoPlayer;
