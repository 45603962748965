import React, { createContext } from 'react'
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import UserPool from './UserPool';

// Context that is imported in other parts of application to keep track of auth state.
const AuthenticationContext = createContext()

/* 
 * Object used for authentication. Relies on AWS Cognito. Wraps rest of application/ props within it's context.
 * 
 * Robby Rice
*/
const Authentication = (props) => {

    // Returns Promise representing session. Used for validation & protected routes.
    const getSession = async () => {
        return await new Promise((resolve, reject) => {
            const user = UserPool.getCurrentUser();
            if (user) {
                user.getCurrentSession(async (err, session) => {
                    if (err) {
                        reject(err)
                    } else {
                        const attributes = await new Promise((resolve, reject) => {
                            user.getUserAttributes((err, attributes) => {
                                if (err) reject(err)
                                else {
                                    const results = {};

                                    for (let attribute of attributes) {
                                        const { Name, Value } = attribute;
                                        results[Name] = Value;
                                    }

                                    resolve(results)
                                }
                            })
                        })

                        resolve({ user, ...session, ...attributes });
                    }
                })
            } else {
                reject('No User')
            }
        })
    }

    // Authentication helper.
    const authenticate = async (username, password) => {
        return await new Promise((resolve, reject) => {
            const user = new CognitoUser({
                Username: username,
                Pool: UserPool
            })

            const authDetails = new AuthenticationDetails({
                Username: username,
                Password: password
            })

            user.authenticateUser(authDetails, {
                onSuccess: (data) => {
                    resolve(data)
                },
                onFailure: (err) => {
                    reject(err)
                },
                newPasswordRequired: (data) => {
                    delete data.email_verified
                    reject({ new_pass: { ...data } })
                }
            })
        })
    };

    // Requests a password reset for a given email.
    const resetPassRequest = async (email) => {
        // console.log('reset pass req', email)
        return await new Promise((resolve, reject) => {
            const user = new CognitoUser({
                Username: email,
                Pool: UserPool
            })

            user.forgotPassword({
                onSuccess: function (data) {
                    resolve(data)
                },
                onFailure: function (data) {
                    reject(data)
                }
            })
        })
    }

    // Resets the password, given a valid email and code.
    const resetPassword = async (email, code, password) => {
        return await new Promise((resolve, reject) => {
            const user = new CognitoUser({
                Username: email,
                Pool: UserPool
            })

            user.confirmPassword(code, password, {
                onSuccess: function (data) {
                    resolve(data)
                },
                onFailure: function (data) {
                    reject(data)
                }
            })
        })
    }

    return (
        <AuthenticationContext.Provider value={{ authenticate, getSession, resetPassRequest, resetPassword }}>
            {props.children}
        </AuthenticationContext.Provider>
    )
}

export { Authentication, AuthenticationContext }