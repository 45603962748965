import { useEffect } from "react";
import { Navigate } from "react-router-dom";

/* 
 * Since our application is a single page (outside of /login), the entire app is protected.
 * There is some opportunity for remembering state/ auth tokens within the browser and logging user in
 * if valid. Didn't get to implementing that.
 * Robby Rice
*/
export default function ProtectedRoute({user, children, checkAuth}) {
    
    // Recheck user with checkAuth here. Will render each time user goes to protected route
    useEffect(() => {
        // checkAuth here
    }, [user])

    if(!user) {
        return <Navigate to={'/login'}/>
    } else {
        return <div>{children}</div>
    }
}