
function Check() {
    return (
        <svg
          fill="currentColor"
          viewBox="0 0 16 16"
          height="2.5rem"
          width="2.5rem"
        >
          <path d="M12.736 3.97a.733.733 0 011.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 01-1.065.02L3.217 8.384a.757.757 0 010-1.06.733.733 0 011.047 0l3.052 3.093 5.4-6.425a.247.247 0 01.02-.022z" fill="rgb(134 239 172)" />
        </svg>
      );
}

export default Check;
