import { Routes, Route, BrowserRouter } from "react-router-dom"
import App from "./App"
import Login from "./auth/Login"
import ProtectedRoute from "./auth/ProtectedRoute"
import { useState, useEffect } from 'react'
import { Authentication } from "./auth/Authentication"

export default function Router() {

    const [user, setUser] = useState(null)
    // Set auth management here, recheck in every ProtectedRoute

    useEffect(() => {
        console.log(user)
    }, [user])

    return (
        <Authentication>
            <BrowserRouter>
                <Routes>
                        <Route path="/" element={<ProtectedRoute user={user}><App agency={user?.idToken.payload['custom:agency']}/></ProtectedRoute>} />
                        <Route path="/login" element={<Login onAuthed={(data) => setUser(data)} />} />
                </Routes>
            </BrowserRouter>

        </Authentication>
    )
}