import React, { useState, useRef, useEffect } from 'react';
import Dashboard from './components/Dashboard';
import axios from 'axios';
import { Snackbar, Alert, CircularProgress } from '@mui/material';
import { PiMapPinDuotone, PiGearSix } from 'react-icons/pi'
import { AiFillSafetyCertificate } from 'react-icons/ai'
import { RiVideoUploadLine } from 'react-icons/ri'
import { FaDrawPolygon } from 'react-icons/fa'
import { IoMailOutline } from 'react-icons/io5'
import { BsActivity } from 'react-icons/bs'
import { BiListUl } from 'react-icons/bi'
import AnnotationList from './components/AnnotationList';
import { AiOutlineArrowRight } from 'react-icons/ai'
import OverviewTable from './components/OverviewTable';
import Inbox from './components/Inbox';
import VideoUpload from './components/popups/VideoUpload';
import { addNotification } from './util/addNotification';
import Badge from '@mui/material/Badge';
import HolisticDatePicker from './components/HolisticDatePicker';
import { FaCodeCompare } from "react-icons/fa6";
import ComparisonTab from './components/compare/ComparisonTab'
import LinearProgress from '@mui/material/LinearProgress';
import { addToLogs } from './util/logging';

function App(props) {
  const [agency, setAgency] = useState(props?.agency.charAt(0).toUpperCase() + props?.agency.slice(1));
  const [notification, setNotification] = useState(false)
  const [inboxNotifications, setInboxNotifications] = useState([])
  const [menu, setMenu] = useState(0)
  const [intersectionSelected, setIntersectionSelected] = useState()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  // TODO: Better naming convention. 'value' is too generic!
  const [value, setValue] = useState(0);
  const [intersections, setIntersections] = useState([]);
  const [currentTime, setCurrentTime] = useState('');

  const [menuHeight, setMenuHeight] = useState('500px');
  const [tableHeight, setTableHeight] = useState('15%');
  const [menuWidth, setMenuWidth] = useState('1000px');
  const [menuAdjust, setMenuAdjust] = useState(false);
  const [alertStatus, setAlertStatus] = useState(true); // handles NotificationAlert.jsx for demo purposes
  const [unreadNotif, setUnreadNotif] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0); // Bar in top right showing upload progress of video

  var imageId = useRef();
  const currentDate = new Date();
  const oneWeekAgo = new Date(currentDate);
  oneWeekAgo.setDate(currentDate.getDate() - 7);

  const [primaryDateRange, setPrimaryDateRange] = useState({
    startDate: oneWeekAgo,
    endDate: currentDate,
    weekends: false
  })

  const threeWeeksAgo = new Date(currentDate);
  threeWeeksAgo.setDate(currentDate.getDate() - 21)

  const twoWeeksAgo = new Date(currentDate);
  twoWeeksAgo.setDate(currentDate.getDate() - 14)


  const [secondaryDateRange, setSecondaryDateRange] = useState({
    startDate: threeWeeksAgo,
    endDate: twoWeeksAgo,
    weekends: true
  })


  useEffect(() => {

    addToLogs("INFO", "User logged in.", `User logged in with agency ${agency}.`, "App", agency)

    const fetchIntersectionList = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/prod/getIntersectionList`, { agency: agency });
        if (response.data) {
          const data = response.data.i_list;
          setIntersections(data);
        } else {
          throw new Error // go to catch block
        }
      } catch (err) {
        addNotification(
          "Failed to fetch intersection list",
          `There was a failure attempting to fetch the intersection list. Please try again later.`,
          "error",
          agency
        )
        addToLogs("ERROR", "Failed to fetch intersection list.", `Could not get intersection list.\nError: ${err}.`, "App", agency)
      }
    }

    fetchIntersectionList()

  }, [agency])

  useEffect(() => {
    const handleMobileDevice = () => {
      if (window.innerWidth < 1000) {
        alert("This application is not optimized for mobile devices. Please use a desktop or laptop computer.")
      }
    }
    handleMobileDevice();
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const options = { month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true };
      setCurrentTime(now.toLocaleTimeString('en-US', options));
    }, 1000);

    return () => clearInterval(interval);
  })

  // Connects to Websocket and fetches notifications
  useEffect(() => {

    const fetchNotifications = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/prod/getNotifications`, {
          agency: agency
        });
        if (response.data) {
          const data = response.data;

          data.sort((a, b) => {
            const dateA = new Date(a.date.S);
            const dateB = new Date(b.date.S);

            return dateB - dateA;
          });

          setInboxNotifications(data)

          const hasUnreadNotifs = data.some(dataPoint => dataPoint.hasRead.BOOL === false);

          setUnreadNotif(hasUnreadNotifs)
        } else {
          throw new Error
        }
      } catch (err) {
        addNotification(
          "Failed to fetch notifications",
          `There was a failure attempting to fetch the notifications. Please try again later.`,
          "error",
          agency
        );
        setInboxNotifications([]);
        addToLogs("WARNING", "Could not fetch notifications.", `Notifications failed to fetch.\nError: ${err}.`, "App", agency)
      }
    }

    fetchNotifications();

    const socket = new WebSocket(`wss://2kb3b505uh.execute-api.us-east-1.amazonaws.com/production?agency=${agency}`);

    socket.onmessage = (event) => {
      const newNotification = JSON.parse(event.data);

      setNotification({
        text: newNotification.content.S,
        severity: newNotification.severity.S, loading: false
      })
      setUnreadNotif(true)
      fetchNotifications()
    };

    return () => {
      socket.close();
    };
  }, [agency]);


  const onAlertClose = () => {
    setMenuHeight('500px');
    setTableHeight('4%');
    setMenuAdjust(true);
    setAlertStatus(false);
  }

  const handleClose = () => {
    setNotification(false)
  }

  const handleMenuClick = (val) => {
    setIntersectionSelected(null)
    setIsDrawerOpen(false)
    setValue(value === val ? 0 : val);
  }

  const handleAnnotationFinish = async (id) => {
    addNotification(
      "Annotation started",
      `Annotation has been completed. Gathering analytics now!`,
      "success",
      agency
    );
    addToLogs("INFO", "Analytics started.", `Analytics request started for ${id}.`, "App", agency)
  }

  const handleIntersectionClick = (e) => {
    setIntersectionSelected(e)
  }

  const handleNotifRedirect = (intersectionId) => {
    setMenu(0)
    setValue(3)
    handleIntersectionClick(intersections.find(intersection => intersection.id === intersectionId))
  }

  /* Drawer functions */
  const closeDrawer = () => {
    setIntersectionSelected(null)
    setIsDrawerOpen(false)
    setValue(3)
  }

  const openDrawer = () => {
    setIsDrawerOpen(true)
  }

  return (
    <div className="wrapper">
      <main className="content">
        <div className="side-nav">
          <div className="button-container">
            <AiFillSafetyCertificate className='nav-button' size={25} />
            <PiMapPinDuotone className='nav-button' size={25} onClick={() => setMenu(0)} />
            <RiVideoUploadLine className='nav-button' size={25} onClick={() =>
              document.getElementById('upload-modal').showModal()} />
            <FaDrawPolygon className='nav-button' size={25} onClick={() => setMenu(1)} />
            <FaCodeCompare className='nav-button' size={25} onClick={() => setMenu(3)} />
          </div>
          <Badge badgeContent={''} color='primary' className='nav-button-container' invisible={!unreadNotif} variant="dot">
            <IoMailOutline className='nav-button' size={25} onClick={() => setMenu(2)} />
          </Badge>
          <PiGearSix className='nav-button' size={25} />
        </div>
        <div className="main-div">
          {menu === 0 && <div className='top-buttons'>
            {/* Progress Bar for Uploads */}
            {(0 < uploadProgress && uploadProgress < 1) &&
              <div className="top-button" style={{ width: '300px' }}>
                <div style={{width: '100%', borderRadius: '50%'}}>
                  <LinearProgress variant="determinate" value={uploadProgress * 100} />
                </div>
              </div>

            }

            {/* Date Picker */}
            <div className={`top-button ${value === 1 ? 'active' : ''}`} onClick={() => handleMenuClick(1)}>
              <span className='dot' style={{ backgroundColor: 'green' }}></span>
              <p>{currentTime}</p>
            </div>


            {/* System Overview */}
            <div className={`top-button ${value === 2 ? 'active' : ''}`} onClick={() => { handleMenuClick(2) }}>
              <BsActivity color='white' size={20} style={{ 'paddingRight': '7px' }} />
              <p>System Overview</p>
            </div>

            {/* Intersection List */}
            <div className={`top-button ${value === 3 ? 'active' : ''}`} onClick={() => { handleMenuClick(3) }}>
              <BiListUl color='white' size={19} style={{ 'paddingRight': '7px' }} />
              <p>Intersection List</p>
            </div>
          </div>}

          {value === 1 && menu === 0 && !isDrawerOpen && (
            <div className='widget-menu date-picker-menu' style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingTop: '10px',
              paddingBottom: '10px'
            }}>
              <HolisticDatePicker primaryDateRange={primaryDateRange} secondaryDateRange={secondaryDateRange}
                onPrimaryDateRangeChange={(dateObj) => { setPrimaryDateRange(dateObj); console.log(dateObj) }} onSecondaryDateRangeChange={(dateObj) => setSecondaryDateRange(dateObj)}
                onCancel={() => setValue(0)} />
            </div>
          )

          }

          {value === 2 && menu === 0 && !isDrawerOpen && (
            <div className="widget-menu"
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: menuWidth,
                height: 'min',
                padding: '20px',
              }}>
              <h4 style={{ margin: '0px 0px 10px 0px', fontSize: '16px' }}>System Overview</h4>
              <OverviewTable tableHeight={tableHeight} setMenuHeight={setMenuHeight} setMenuWidth={setMenuWidth} menuAdjust={menuAdjust} agency={agency}
                startDate={primaryDateRange.startDate} endDate={primaryDateRange.endDate} weekends={primaryDateRange.weekends} />
            </div>
          )}

          {/* Intersection list */}
          {value === 3 && menu === 0 && !isDrawerOpen && (
            <div className="widget-menu intersection-widget">
              <ul style={{ paddingLeft: '0px', marginRight: '10px', marginLeft: '10px' }}>
                {intersections && Array.isArray(intersections) && intersections?.map((intersection, idx) => (
                  <div onClick={(e) => handleIntersectionClick(intersection)}
                    key={idx}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '2px',
                      margin: '2px',
                      borderBottom: '1px solid #2B2B3D',
                      color: 'white',
                      cursor: 'pointer'
                    }}
                  >
                    <div style={{ flexGrow: 1, paddingRight: '10px', paddingLeft: '6px', paddingTop: '6px', paddingBottom: '6px' }}>
                      <div style={{ fontWeight: '300', color: '#D0D5DD' }} >{intersection.id}</div>
                    </div>
                    <AiOutlineArrowRight size={20} color='#D0D5DD' style={{ paddingRight: '10px' }} />
                  </div>
                ))}
              </ul>
            </div>
          )}

          {menu === 0 && <Dashboard dateRange={primaryDateRange} agency={agency} intersections={intersections} intersectionSelected={intersectionSelected} setIntersectionSelected={setIntersectionSelected}
            closeDrawer={() => { setValue(0); closeDrawer() }} openDrawer={() => { setValue(0); openDrawer() }} isDrawerOpen={isDrawerOpen} setValue={setValue} />}
          {menu === 1 && <AnnotationList agency={agency} intersections={intersections} handleFinish={handleAnnotationFinish} />}
          {menu === 2 && <Inbox handleNotifRedirect={handleNotifRedirect} agency={agency} inboxNotifications={inboxNotifications} setInboxNotifications={setInboxNotifications} intersections={intersections} />}
          {menu === 3 && <ComparisonTab intersections={intersections} agency={agency} primaryDateRange={primaryDateRange} secondaryDateRange={secondaryDateRange}
            onPrimaryDateChange={(dateObj) => { setPrimaryDateRange(dateObj); console.log(dateObj) }} onSecondaryDateChange={(dateObj) => setSecondaryDateRange(dateObj)} />}
        </div>
      </main>

      {/* Upload modal */}
      <dialog id='upload-modal' style={{ border: '1px solid var(--neutral-700)' }}>
        <VideoUpload onClose={() => document.getElementById('upload-modal').close()} intersections={intersections} agency={agency} updateUploadProgress={(num) => setUploadProgress(num)} />
      </dialog>

      {/* Notification Snackbar */}
      <Snackbar open={notification} onClose={handleClose}>
        {notification &&
          <Alert onClose={handleClose} severity={notification.severity}
            anchororigin={{ vertical: 'bottom', horizontal: 'right' }} style={{}} iconMapping={{
              info: <CircularProgress size={'20px'} />
            }}>
            {notification.text}
          </Alert>
        }
      </Snackbar>
    </div>
  );
}

export default App;
