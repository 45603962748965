import "./inbox/Inbox.css"
import LogList from "./inbox/LogList"
import axios from "axios"
import { useState, useEffect } from "react"
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import Mail from "@mui/icons-material/Mail";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

const Inbox = (props) => {
  const {inboxNotifications, setInboxNotifications, agency, intersections} = props
  const [logTypeFilterValue, setLogTypeFilterValue] = useState('')
  const [intersectionIdFilterValue, setIntersectionIdFilterValue] = useState('')
  const [filteredInboxNotif, setFilteredInboxNotif] = useState(inboxNotifications)
  const [collapseAll, setCollapseAll] = useState(false);
  const [expandAll, setExpandAll] = useState(false);

  useEffect(()=>{
    if(!logTypeFilterValue && !intersectionIdFilterValue){
        setFilteredInboxNotif(inboxNotifications)
    } else{
        const filterNotif = []
        if(logTypeFilterValue || intersectionIdFilterValue){
            filterNotif.push(...inboxNotifications.filter((notif)=>{
                if (logTypeFilterValue && intersectionIdFilterValue){
                    return notif.severity?.S == logTypeFilterValue && notif.redirect?.S == intersectionIdFilterValue;
                }
                return (notif.severity?.S == logTypeFilterValue && logTypeFilterValue) || (notif.redirect?.S == intersectionIdFilterValue && intersectionIdFilterValue)
   
            }))
            setFilteredInboxNotif(filterNotif)
        }
    }
  },[logTypeFilterValue, intersectionIdFilterValue])

  const setAllRead = async () =>{
    const updatedNotifIds = []
    const updatedInboxNotifications = inboxNotifications.map(notif => {
        if (!notif.hasRead['BOOL']) {
            updatedNotifIds.push(notif.id.S)
          return { ...notif, hasRead: {'BOOL': true} };
        }
        return notif;
      });


    setInboxNotifications(updatedInboxNotifications)

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/prod/updateNotification`, {
        agency: agency,
        notificationIds: updatedNotifIds
    })
  }

  const handleCollapseAll = () => {
        setCollapseAll(true);
        setExpandAll(false);
  };

    // Function to handle expand all button click
    const handleExpandAll = () => {
        setCollapseAll(false);
        setExpandAll(true);
    };
    
    return (
        <>
            <div style={styles.container}>
                <div style={styles.titleContainer}>
                    <h1 style={styles.inboxTitle}>Activity Logs</h1>
                    
                </div>

                <div style={styles.filterContainer}>
                    {/* <p style={{fontSize: '18px', marginLeft: '10px', marginTop:'10px'}} >Filter Logs</p> */}
                    <div style={{display:'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
                        <div style={{gap: '10px', display:'flex', marginLeft: '10px', marginBottom: '10px', width:'50%', alignItems: 'center' }}>
                            <p style={{whiteSpace: 'nowrap'}}>Log Type</p>
                            <select id="log-type-dropdown" 
                                style={{...styles.filterInput, width: '35%'}}
                                className="filter-input"
                                value={logTypeFilterValue}
                                onChange={(e) => setLogTypeFilterValue(e.target.value)}
                            >
                                <option value="">All</option>
                                <option value="success">Success</option>
                                <option value="warning">Warning</option>
                                <option value="info">Info</option>
                            </select>
                            <p style={{ whiteSpace: 'nowrap'}}>Intersection ID</p>
                            <select id="intersection-id-dropdown" 
                                value={intersectionIdFilterValue}
                                onChange={(e)=>setIntersectionIdFilterValue(e.target.value)}
                                style={{...styles.filterInput, width: '85%'}}
                                className="filter-input"
                            >
                                <option value="">All</option>
                                {
                                    intersections.map((intersection, index)=>{
                                        return(
                                            <option key={index} value={intersection.id} >{intersection.id}</option>
                                        )
                                    })

                                }
                            </select>
                        </div>
                        <div style={{display: 'flex', gap:'10px', marginRight: '10px', marginBottom: '10px'}}>
                            <button className="activity-log-btn" onClick={setAllRead}>Mark All as Read</button>
                            <button className="activity-log-btn" onClick={handleCollapseAll}>Collapse All</button>
                            <button className="activity-log-btn" onClick={handleExpandAll}>Expand All</button>
                        </div>
                    </div>
                </div>
                <div style={styles.notifContainer}>
                    <LogList 
                        handleNotifRedirect={props.handleNotifRedirect} 
                        inboxNotifications={filteredInboxNotif} 
                        setInboxNotifications={setInboxNotifications}
                        collapseAll={collapseAll}
                        expandAll={expandAll}
                        setCollapseAll={setCollapseAll}
                        setExpandAll={setExpandAll}
                    />
                </div>
            </div>
        </>
  )
}

const styles = {
    container: { 
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        alignItems: 'center',
        overflowY: 'auto',
        backgroundColor: 'rgb(17 24 39)',
    },
    titleContainer:{
        width: '85%', 
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end'
    },
    inboxTitle: {
        marginTop: '25px',
        fontSize: '30px',
    },
    notifContainer: {
        width: '85%',
        height:'100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
    },
    filterContainer:{
        display: 'flex',
        flexDirection: 'column',
        width: '85%',
        height: 'auto',
        backgroundColor: 'rgba(31, 41, 55, 0.5)',
        borderRadius: '10px',
        gap: '10px',
        marginTop: '8px'
    },
    filterInput: {
        // width: '100%',
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '12px',
        paddingRight: '12px',
        // marginBottom:'10px',
        marginRight: '10px',
        border: "0.5px solid rgb(55 65 81)",
        backgroundColor: 'rgb(31 41 55)',
        borderRadius: '10px',
        color: 'rgb(229 231 235)',
        fontSize: '16px'
    }
}

export default Inbox