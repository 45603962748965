import { useEffect } from "react"
import { useState } from "react"
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress';
import { MdErrorOutline } from "react-icons/md";
import './compare.css'
import DatePickerModal from '../popups/DatePicker'
import { addToLogs } from '../../util/logging'

/* 
 * Comparison tab. Lots of data loading/ state changes in this file.
 * Opportunity for caching.
 * Robby Rice
*/
export default function ComparisonTab({ primaryDateRange, secondaryDateRange, agency, intersections, onPrimaryDateChange, onSecondaryDateChange }) {

    // data objs can be loading, error, missing, or the data obj
    const [primaryData, setPrimaryData] = useState('loading')
    const [secondaryData, setSecondaryData] = useState('loading')
    const [primaryDate, setPrimaryDate] = useState(primaryDateRange)
    const [secondaryDate, setSecondaryDate] = useState(secondaryDateRange)
    const [intersectionSelected, setIntersectionSelected] = useState(intersections[0].id)
    const [displayPrimaryDate, setDisplayPrimaryDate] = useState(false)
    const [displaySecondaryDate, setDisplaySecondaryDate] = useState(false)

    useEffect(() => {
        setPrimaryDate(primaryDateRange)
        getData()

        async function getData() {
            const primaryDataReturn = await fetchIntersectionDataByTime(intersectionSelected, primaryDateRange)
            
            if (primaryDataReturn?.statusCode === 200) {
                setPrimaryData(primaryDataReturn.body.intersection_data[0])
            } else if (primaryDataReturn?.statusCode === 404) {
                setPrimaryData('missing')
            } else {
                setPrimaryData('error')
            }
        }
    }, [primaryDateRange])

    useEffect(() => {
        setSecondaryDate(secondaryDateRange)
        getData()

        async function getData() {
            const secondaryDataReturn = await fetchIntersectionDataByTime(intersectionSelected, secondaryDateRange)

            if (secondaryDataReturn?.statusCode === 200) {
                setSecondaryData(secondaryDataReturn.body.intersection_data[0])
            } else if (secondaryDataReturn?.statusCode === 404) {
                setSecondaryData('missing')
            } else {
                setSecondaryData('error')
            }
        }
    }, [secondaryDateRange])

    const fetchIntersectionDataByTime = async (intersectionId, dateRange) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/prod/systemOverview`,
                {
                    agency: agency,
                    amount: "1",
                    intersection: intersectionId,
                    start_date: dateRange.startDate,
                    end_date: dateRange.endDate,
                    weekends: dateRange.weekends
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            const responseData = response?.data

            return responseData

        } catch (err) {
            console.error(err)
            addToLogs("WARNING", "Intersection comparison data fetching failed.", `Couldn't get comparison intersection data.\nError: ${err}.`, "ComparisonTab", agency)
            return "error"
        }
    }

    useState(() => {
        if (!intersections || intersections.length < 1) {
            return <></>
        } else {
            getInitialData()
        }

        async function getInitialData() {
            // Get primary data
            const primData = await fetchIntersectionDataByTime(intersectionSelected, primaryDate)
            // Get secondary data
            const secondaryData = await fetchIntersectionDataByTime(intersectionSelected, secondaryDate)

            if (primData?.statusCode === 404) {
                setPrimaryData('missing')
            } else if (primData?.statusCode !== 200) {
                setPrimaryData('error')
            } else {
                setPrimaryData(primData.body.intersection_data[0])
            }

            if (secondaryData?.statusCode === 404) {
                setSecondaryData('missing')
            } else if (secondaryData?.statusCode !== 200) {
                setSecondaryData('error')
            } else {
                setSecondaryData(secondaryData.body.intersection_data[0])
            }
        }
    }, [intersections])

    const formatDate = (date) => {
        const options = { month: 'long', day: '2-digit', year: 'numeric' };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

        return formattedDate;
    }

    const handleIntersectionSelect = async (e) => {
        setIntersectionSelected(e.target.value)
        setPrimaryData('loading')
        setSecondaryData('loading')

        const primaryDataReturn = await fetchIntersectionDataByTime(e.target.value, primaryDate)
        const secondaryDataReturn = await fetchIntersectionDataByTime(e.target.value, secondaryDate)

        if (primaryDataReturn?.statusCode === 200) {
            setPrimaryData(primaryDataReturn.body.intersection_data[0])
        } else if (primaryDataReturn?.statusCode === 404) {
            setPrimaryData('missing')
        } else {
            setPrimaryData('error')
        }

        if (secondaryDataReturn?.statusCode === 200) {
            setSecondaryData(secondaryDataReturn.body.intersection_data[0])
        } else if (secondaryDataReturn?.statusCode === 404) {
            setSecondaryData('missing')
        } else {
            setSecondaryData('error')
        }
    }


    return (
        <div style={{ minHeight: '100vh', maxHeight: '100vh', width: '100%' }}>
            <div style={{
                justifyContent: 'center', alignItems: 'center',
                paddingLeft: '30px', paddingRight: '30px', display: 'flex', flexDirection: 'column'
            }}>
                <h1 style={{ marginTop: '20px', marginBottom: '30px' }}>{agency}</h1>
                <div style={{ width: '800px', maxWidth: '800px', height: '300px', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                        {/* select */}
                        <select
                            id="primaryIntersectionSelect"
                            value={intersectionSelected}
                            onChange={(e) => handleIntersectionSelect(e)}
                            className="compare-intersection-select"
                        >
                            {intersections.map(intersection => (
                                <option key={intersection.id} value={intersection.id}>
                                    {intersection.id}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{
                        minHeight: '400px', overflowY: 'scroll', display: 'flex', flexDirection: 'row', position: 'relative',
                        backgroundColor: 'var(--neutral-700)', borderRadius: '10px', border: '2px solid var(--neutral-900)'
                    }}>
                        {/* Left Section */}
                        <div style={{ flex: '1', width: '50%', overflowX: 'auto', }}>

                            {primaryData === 'loading' && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <CircularProgress />
                            </div>}

                            {primaryData === 'error' && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <MdErrorOutline size={50} color="white" />
                            </div>}

                            {primaryData === 'missing' && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <p style={{ color: 'var(--neutral-200)' }}>No data for selected intersection & date range.</p>
                            </div>}

                            {primaryData?.[intersectionSelected] && <div style={{ width: '100%' }}>
                                <table style={{ width: '100%', padding: '5px' }}>
                                    {Object.keys(primaryData[intersectionSelected]).map(key => (
                                        <>
                                            <tr style={{ textAlign: 'left', marginTop: '3px', color: 'var(--neutral-100)', backgroundColor: 'var(--neutral-800)' }}>
                                                <th>{key}</th>
                                            </tr>
                                            {Object.keys(primaryData[intersectionSelected][key]).map(metric => (
                                                <tr style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                                    <td style={{ color: 'var(--neutral-200)' }}>{metric}</td>
                                                    <td style={{ color: 'var(--neutral-300)' }}>{primaryData[intersectionSelected][key][metric]}</td>
                                                </tr>
                                            ))}
                                        </>
                                    ))}
                                </table>
                            </div>}
                        </div>
                        <div style={{ position: 'absolute', top: 0, bottom: 0, left: '50%', width: '2px', backgroundColor: 'var(--neutral-900)' }}></div>
                        {/* Right Section */}
                        <div style={{ flex: '1', width: '50%', overflowX: 'auto' }}>

                            {secondaryData === 'loading' && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <CircularProgress />
                            </div>}

                            {secondaryData === 'error' && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <p>Couldn't load your data.</p>
                            </div>}

                            {secondaryData === 'missing' && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <p style={{ color: 'var(--neutral-200)' }}>No data for selected intersection & date range.</p>
                            </div>}

                            {secondaryData?.[intersectionSelected] && <div style={{ width: '100%' }}>
                                <table style={{ width: '100%', padding: '5px' }}>
                                    {Object.keys(secondaryData[intersectionSelected]).map(key => (
                                        <>
                                            <tr style={{ textAlign: 'left', marginTop: '3px', color: 'var(--neutral-100)', backgroundColor: 'var(--neutral-800)' }}>
                                                <th>{key}</th>
                                            </tr>
                                            {Object.keys(secondaryData[intersectionSelected][key]).map(metric => (
                                                <tr style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                                    <td style={{ color: 'var(--neutral-200)' }}>{metric}</td>
                                                    <td style={{ color: 'var(--neutral-300)' }}>{secondaryData[intersectionSelected][key][metric]}</td>
                                                </tr>
                                            ))}
                                        </>
                                    ))}
                                </table>
                            </div>}
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '50%', position: 'relative' }}>
                            <h4 onClick={() => {setDisplayPrimaryDate(pd => !pd); setDisplaySecondaryDate(false)}} style={{ cursor: 'pointer' }}>
                                {formatDate(primaryDateRange.startDate)} - {formatDate(primaryDateRange.endDate)}
                            </h4>
                            {displayPrimaryDate && (
                                <div style={{ position: 'absolute', top: '110%', left: '-150%', zIndex: 100, backgroundColor: 'var(--neutral-900)', borderRadius: '10px', padding: '10px' }}>
                                    <DatePickerModal dateRangeProp={primaryDateRange} onCancel={() => setPrimaryDate(false)} onSubmit={(o) => onPrimaryDateChange(o)} />
                                </div>
                            )}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '50%', position: 'relative' }}>
                            <h4 onClick={() => {setDisplaySecondaryDate(pd => !pd); setDisplayPrimaryDate(false)}} style={{ cursor: 'pointer' }}>
                                {formatDate(secondaryDateRange.startDate)} - {formatDate(secondaryDateRange.endDate)}
                            </h4>
                            {displaySecondaryDate && (
                                <div style={{ position: 'absolute', top: '110%', left: '-50%', zIndex: 100, backgroundColor: 'var(--neutral-900)', borderRadius: '10px', padding: '10px' }}>
                                    <DatePickerModal dateRangeProp={secondaryDateRange} onCancel={() => setSecondaryDate(false)} onSubmit={(o) => onSecondaryDateChange(o)} />
                                </div>
                            )}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}