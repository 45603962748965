import { useState } from "react";
import DatePicker from "react-datepicker"
import EastIcon from '@mui/icons-material/East';
import { useEffect } from "react";


// startDate, endDate, onCancel, onSubmit(startDate, endDate)
export default function DatePickerModal({ dateRangeProp, onCancel, onSubmit }) {

    const [dateRange, setDateRange] = useState([new Date(dateRangeProp.startDate), new Date(dateRangeProp.endDate)]);
    const [startDate, endDate] = dateRange;
    const [startDateChange, setStartDateChange] = useState(new Date(dateRangeProp.startDate))
    const [endDateChange, setEndDateChange] = useState(new Date(dateRangeProp.endDate))
    const [weekends, setWeekends] = useState(dateRangeProp.weekends)

    useEffect(() => {
        if (!startDate || !endDate) return;
        document.getElementById('start-date-input').value = formatDate(startDate)
        document.getElementById('end-date-input').value = formatDate(endDate)
    }, dateRange)

    function handleKeyPress(event, dateType) {
        if (event.key === 'Enter') {
            dateChangeRequest(dateType);
        }
    }

    const datePickerSelections = [
        { name: 'Today', dateRange: getDateRange(0, 0) },
        { name: 'Yesterday', dateRange: getDateRange(-1, -1) },
        { name: 'Last 7 Days', dateRange: getDateRange(-6, 0) },
        { name: 'Last 30 Days', dateRange: getDateRange(-29, 0) },
        { name: 'Last 90 Days', dateRange: getDateRange(-89, 0) },
        { name: 'Last Month', dateRange: getDateRange(-30, 0) },
        { name: 'Last Year', dateRange: getDateRange(-365, 0) },
    ]

    const formatDate = (date) => {
        if (!startDate || !endDate) {
            return
        }
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();

        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        const month = months[monthIndex];

        return `${day} ${month} ${year}`;
    };

    const parseDate = (input) => {

        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        const dateRegex = /^(\d{1,2})\s+(\w+)\s+(\d{4})$/;
        const match = input.match(dateRegex);

        if (match) {
            const [, day, month, year] = match;
            const monthIndex = months.indexOf(month);

            if (monthIndex !== -1) {
                const parsedDate = new Date(parseInt(year), monthIndex, parseInt(day));

                if (!isNaN(parsedDate.getTime())) {
                    return parsedDate;
                }
            }
        }

        return null; // Return null for invalid or unmatched input
    };

    const dateChangeRequest = (dateType) => {

        const handleFail = (type) => {
            if (type === 'start') {
                document.getElementById('start-date-input').value = formatDate(startDate)
                setStartDateChange(formatDate(startDate))
            }
            else if (type === 'end') {
                document.getElementById('end-date-input').value = formatDate(endDate)
                setEndDateChange(formatDate(endDate))
            }
        }

        var input = document.getElementById('start-date-input').value;

        if (dateType === 'end') {
            input = document.getElementById('end-date-input').value
        }

        const parsedDate = parseDate(input);

        if (parsedDate) {
            // Valid date
            console.log('Parsed Date:', parsedDate);
            if (dateType === 'start') {
                if (parsedDate <= endDate) setDateRange([parsedDate, endDate])
                else handleFail(dateType)

            }
            else if (dateType === 'end') {
                if (parsedDate >= startDate) setDateRange([startDate, parsedDate])
                else handleFail(dateType)
            }
        } else {
            // Invalid date format
            console.log('Invalid Date Format');
            handleFail(dateType)

        }
    }

    function getDateRange(startOffset, endOffset) {
        const today = new Date();
        const from = new Date(today);
        const to = new Date(today);

        from.setDate(today.getDate() + startOffset);
        to.setDate(today.getDate() + endOffset);

        return [from, to];
    }

    function handleCancel() {
        onCancel()
    }

    function handleSetDate() {
        // Various handlers

        // TODO: Alert users to a failed check in component UI

        onSubmit({ startDate, endDate, weekends })
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>

            <div style={{ display: 'flex', flexDirection: 'column', margin: '2px', marginRight: '20px', minWidth: '150px' }}>
                {datePickerSelections.map((date, index) => {
                    return (
                        <div className="date-picker-selection" onClick={() => setDateRange(date.dateRange)} key={index}>
                            {date.name}
                        </div>
                    )
                })}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', 'marginBottom': '12px' }}>
                    <input
                        type='text'
                        // value={formatDate(startDate)}
                        defaultValue={formatDate(startDate)}
                        onChange={(e) => setStartDateChange(e.target.value)}
                        onKeyDown={(e) => handleKeyPress(e, 'start')}
                        className="text-input"
                        id='start-date-input'
                    />
                    <EastIcon sx={{ color: '#6A7385' }} />
                    <input
                        type='text'
                        // value={formatDate(endDate)}
                        defaultValue={formatDate(endDate)}
                        onChange={(e) => setEndDateChange(e.target.value)}
                        onKeyDown={(e) => handleKeyPress(e, 'end')}
                        className="text-input"
                        id='end-date-input'
                    />
                </div>
                <div>
                    <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => { setDateRange(update) }}
                        monthsShown={2}
                        inline
                        isClearable={true}
                    />
                </div>
                <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'end', gap: '15px', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <label htmlFor="weekends" style={{ marginLeft: '5px' }}>Include weekends?</label>
                        <input
                            type="checkbox"
                            id="weekends"
                            checked={weekends}
                            onChange={(e) => setWeekends(e.target.checked)}
                        />
                    </div>
                    <button style={{ padding: '14px', borderRadius: '4px', backgroundColor: '#373848', fontSize: '14px', fontFamily: 'inter' }} onClick={handleCancel}>Cancel</button>
                    <button style={{ padding: '14px', borderRadius: '4px', backgroundColor: '#00A19C', fontSize: '14px', color: 'white', fontFamily: 'inter' }} onClick={handleSetDate}>Set Date</button>
                </div>

            </div>

        </div>
    )
}