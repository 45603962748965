import { useState } from 'react';
import ModeIcon from '@mui/icons-material/Mode';
import HelpIcon from '@mui/icons-material/Help';

/* 
 * Component used in Annotation that show zones to draw and their behaviors.
 * 
 * Robby Rice
*/
const PolygonPicker = (props) => {
    const [isHovered, setIsHovered] = useState(false)

    return (
        <div className='polygon-picker' style={{ backgroundColor: props?.selected ? '#2B2B3D' : '' }}
            onClick={() => props.onClick()} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}
        >
            <div style={{ 'display': 'flex', 'flexDirection': 'row', 'alignItems': 'center' }}>
                <ModeIcon style={{ 'color': props?.drawn ? '#00A19C' : '#D0D5DD', 'width': '20px' }} />
                <p style={{ 'paddingLeft': '3px', 'color': props?.drawn ? '#00A19C' : '#D0D5DD' }}>{props?.name}</p>
            </div>
            <div style={{ 'display': 'flex', 'flexDirection': 'row', 'alignItems': 'center' }}>
                {isHovered && <HelpIcon className='help-icon' id='help-icon' />}
                <div>
                    <div style={{ 'height': '7px', 'width': '7px', 'borderRadius': '50%', 'backgroundColor': props?.drawn ? '#00A19C' : '#D0D5DD' }}></div>
                </div>
            </div>
        </div>
    )

}

export default PolygonPicker