
function Cross() {
  return (
    <svg
      viewBox="0 0 21 21"
      fill="currentColor"
      height="2.5rem"
      width="2.5rem"
    >
      <g
        fill="rgb(252 165 165)"
        fillRule="evenodd"
        stroke="rgb(252 165 165)"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M15.5 15.5l-10-10zM15.5 5.5l-10 10"/>
      </g>
    </svg>
  );
}

export default Cross;
