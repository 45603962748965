import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import LongDuration from "./info_panels/LongDuration";
import RLR from "./info_panels/RLR";
import YLR from "./info_panels/YLR";
import PedConflict from "./info_panels/PedConflict";
import GapAcceptance from "./info_panels/GapAcceptance";
import SplitFailure from "./info_panels/SplitFailure";
import { MdArrowBack } from "react-icons/md";
import { TbUpload } from "react-icons/tb";
import { AiOutlineVideoCameraAdd } from "react-icons/ai";
import { addNotification } from "../util/addNotification";
import { addToLogs } from '../util/logging'

const Overlay = ({
  show,
  onClose,
  intersection,
  agency,
  startDate,
  endDate,
  handleStartDate,
  handleEndDate,
  setValue,
  weekends
}) => {
  const [formattedData, setFormattedData] = useState();
  const [videoObj, setVideoObj] = useState();
  const [metric, setMetric] = useState("long_duration");
  const [noData, setNoData] = useState(false);
  const [systemInfo, setSystemInfo] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  
  const demoMode = (process.env.REACT_APP_DEMO_MODE === 'true')

  useEffect(() => {
    async function fetchData() {
      try {
        // console.log("Fetching data for", intersection, agency);
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/prod/getData`,
          {
            agency: agency,
            intersection: intersection,
            startDate: formatDate(startDate),
            endDate: formatDate(endDate),
            weekends: weekends
          },
          {
            headers: {
              "Cache-Control": "no-cache",
              Pragma: "no-cache",
            },
          }
        );
        if (response.data) {
          if (response.data.length === 0) {
            setNoData(true);
            setFormattedData();
            return;
          }

          const analyticPoints = await Promise.all(
            response.data.map(parseDyanmoDBDataPoint)
          );

          const updatedAnalyticData = analyticPoints.reduce((result, item) => {
            if (!result[item.intersection]) {
              result[item.intersection] = [];
            }
            result[item.intersection].push(item);
            return result;
          }, {});

          const groupedData = groupData(updatedAnalyticData);
          setFormattedData(groupedData);

        } else {
          throw new Error;
        }
      } catch (error) {
        addNotification(
          "Failed to get data.",
          `There was an error in fetching data for ${intersection}, ${agency}. Please try again.`,
          "error",
          agency
        );
        addToLogs("ERROR", "Failed to get intersection data.", `Failed to grab intersection data for ${intersection}.\nError: ${error}`, "InfoDrawer", agency)
      }
    }

    if (show) {
      fetchData();
      fetchVideos();
      fetchQuickInfo();
    }
  }, [show, intersection, agency, startDate, endDate]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1132); // Adjust the breakpoint as needed
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  async function fetchVideos() {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/prod/getVideo`,
        {
          agency: agency,
          intersection: intersection,
          start_date: formatDate(startDate),
          end_date: formatDate(endDate),
        }
      );

      if (response.status === 200) {
        const responseJson = response.data;
        console.log('video obj', responseJson)
        // if(!responseJson.video_data || responseJson.video_data.length === 0) {}
        setVideoObj(responseJson);
      } else {
        throw new Error(`Server did not respond with a status of 200: ${JSON.stringify(response)}`);
      }
    } catch (error) {
      addNotification(
        "Failed to start analytics",
        `There was an error in fetching video data for ${intersection}, ${agency}. Please try again.`,
        "error",
        agency
      );
      console.error(error)
      addToLogs("WARNING", "Failed to fetch videos.", `Intersection ${intersection} failed to fetch.\nstart_date: ${formatDate(startDate)}, end_date: ${formatDate(endDate)}.\nError: ${error}`, "InfoDrawer", agency)
    }
  }

  async function fetchQuickInfo() {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/prod/systemOverview`,
        {
          agency: agency,
          amount: "1",
          intersection: intersection,
          start_date: startDate,
          end_date: endDate
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const responseJson = response.data;
        setSystemInfo(responseJson.body.intersection_data[0]);
      } else {
        throw new Error(`Server did not respond with a status of 200: ${response}`);
      }
    } catch (err) {
      console.error(err)
      addToLogs("WARNING", "Couldn't fetch quick info.", `Intersection ${intersection} failed to fetch.\nstart_date: ${startDate}, end_date: ${endDate}, amount: 1`, "InfoDrawer", agency)
    }
  }

  function formatDate(date) {
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  }

  async function parseDyanmoDBDataPoint(item) {
    const intersectionData = item.data.L;

    const dataPoints = [];

    for (const point of intersectionData) {
      const point_data = point.M;
      const data = {};

      for (const key in point_data) {
        const type = Object.keys(point_data[key])[0];
        const value = point_data[key][type];
        data[key] = type === "N" ? Number(value) : value;
      }
      dataPoints.push(data);
    }

    return {
      eventType: item.event_type.S,
      agency: item.agency.S,
      start_date: item.start_date.S,
      operator: item.operator.S,
      intersection: item.intersection.S,
      data: dataPoints,
    };
  }

  function groupData(data) {
    const iid = intersection;

    const groupedData = data[iid].reduce((acc, item) => {
      const { eventType, data } = item;
      if (!acc[eventType]) {
        acc[eventType] = [];
      }
      acc[eventType].push(...data);
      return acc;
    }, {});

    const newArray = Object.keys(groupedData).map((eventType) => ({
      eventType,
      data: groupedData[eventType],
    }));

    return newArray;
  }

  function determineDotColor(num) {
    if (num > 90) return "#32D583";
    else if (num > 50 && num <= 90) return "#FDB022";
    else if (num <= 50) return "#F97066";
    else return "#D0D5DD";
  }

  function determinePercentageDisplay(val) {
    if (!val) return "-";
    else if (val === undefined) return "-";
    else if (val === "-") return "-";
    else return `${val}%`;
  }

  function determineSecondsDisplay(val) {
    if (!val) return "-";
    else if (val === undefined) return "-";
    else if (val === "-") return "-";
    else return `${val} sec`;
  }


  async function handleExportData() {
    try {
      const jsonData = formattedData

      let csv = "intersection,hour,conflict_type,number_of_occurrences\n";

      jsonData.forEach(item => {
        const eventType = item.eventType;
        const data = item.data;
        const hourCounts = {}

        data.forEach(entry => {
          const hour = entry.hour;
          if (hourCounts[hour]) {
            hourCounts[hour]++;
          } else {
            hourCounts[hour] = 1;
          }

        });
        Object.keys(hourCounts).forEach(item => {
          csv += `${intersection},` + item + "," + eventType + "," + hourCounts[item] + "\n";
        })
      });
      console.log("csv ", csv)
      const url = window.URL.createObjectURL(new Blob([csv], { type: 'text/csv' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${intersection}_${formatDate(new Date())}.csv`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      addNotification(
        "Failed to download CSV",
        `Unable to parse and download CSV for intersection ${intersection}. Please try again.`,
        "error",
        agency
      );
    }
  }

  if (!show || !intersection) {
    return null;
  }
  return (
    <div className="overlay">
      <div className="overlay-top">
        <div style={{ display: "flex" }}>
          <div>
            <button
              onClick={onClose}
              style={{ marginRight: "12px", padding: "10px" }}
            >
              <MdArrowBack size={24} />
            </button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <h4 style={{ fontSize: "20px" }}>{intersection}</h4>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                color: "#999AAD"
              }}
            >
              <p style={{ fontSize: "12px" }}>
                {startDate.getFullYear() !== endDate.getFullYear()
                  ? `${startDate.toLocaleDateString("en-US", {
                    month: "short",
                    day: "2-digit",
                    year: "numeric",
                  })} - ${endDate.toLocaleDateString("en-US", {
                    month: "short",
                    day: "2-digit",
                    year: "numeric",
                  })}`
                  : `${startDate.toLocaleDateString("en-US", {
                    month: "short",
                    day: "2-digit",
                  })} - ${endDate.toLocaleDateString("en-US", {
                    month: "short",
                    day: "2-digit",
                  })}`}
              </p>
            </div>
          </div>
        </div>
        <div style={{ 'display': 'flex', 'alignItems': 'center' }}>
          <button
            className="icon-button"
            onClick={handleExportData}
          ><TbUpload size={isSmallScreen ? 24 : 16} style={{ 'marginRight': '4px' }} />{!isSmallScreen && <p>Export CSV</p>}</button>
          <button
            className="icon-button"
            onClick={() => {
              onClose()
              setValue(0)
              document.getElementById('upload-modal').showModal()
            }
            }
          ><AiOutlineVideoCameraAdd size={isSmallScreen ? 25 : 17} style={{ 'marginRight': '4px' }} />{!isSmallScreen && <p>Upload Video</p>}</button>
        </div>
      </div>
      <div className="overlay-content">
        {/* Quick alert section TODO: Make dynamic */}
        <div className="grid-container">
          <div className="grid-item">
            <div className="content">
              <div>
                <p>
                  {systemInfo
                    ? determinePercentageDisplay(
                      demoMode ? 2.3 : 
                      systemInfo?.[intersection]?.long_duration
                        ?.percent_cycles
                    )
                    : "-"}
                </p>
                <p
                  style={{
                    paddingTop: "4px",
                    fontSize: "12px",
                    color: "#999AAD",
                  }}
                >
                  Long Duration %
                </p>
              </div>
              <div
                className="dot"
                style={{
                  backgroundColor: determineDotColor(
                    demoMode ? 2.3 : 
                    systemInfo?.[intersection]?.long_duration?.percent_cycles
                  )
                }}
              ></div>
            </div>
          </div>
          <div className="grid-item">
            <div className="content">
              <div>
                <p>
                  {systemInfo
                    ? determineSecondsDisplay(
                      demoMode ? 3.2 : 
                      systemInfo?.[intersection]?.gap_acceptance?.mean
                    )
                    : "-"}
                </p>
                <p
                  style={{
                    paddingTop: "4px",
                    fontSize: "12px",
                    color: "#999AAD",
                  }}
                >
                  Gap Acceptance
                </p>
              </div>
              <div
                className="dot"
                style={{
                  backgroundColor: determineDotColor(
                    demoMode ? 3.2 : 
                    systemInfo?.[intersection]?.gap_acceptance?.mean
                  )
                }}
              ></div>
            </div>
          </div>
          <div className="grid-item">
            <div className="content">
              <div>
                <p>
                  {systemInfo
                    ? determinePercentageDisplay(
                      demoMode ? 1.5 : 
                      systemInfo?.[intersection]?.red_light_running
                        ?.percent_cycles
                    )
                    : "-"}
                </p>
                <p
                  style={{
                    paddingTop: "4px",
                    fontSize: "12px",
                    color: "#999AAD",
                  }}
                >
                  Red Light Running %
                </p>
              </div>
              <div
                className="dot"
                style={{
                  backgroundColor: determineDotColor(
                    demoMode ? 1.5 : 
                    systemInfo?.[intersection]?.red_light_running
                      ?.percent_cycles
                  )
                }}
              ></div>
            </div>
          </div>
          <div className="grid-item">
            <div className="content">
              <div>
                <p>
                  {systemInfo
                    ? determinePercentageDisplay(
                      demoMode ? 9.6 :
                      systemInfo?.[intersection]?.split_failure
                        ?.percent_cycles
                    )
                    : "-"}
                </p>
                <p
                  style={{
                    paddingTop: "4px",
                    fontSize: "12px",
                    color: "#999AAD",
                  }}
                >
                  Split Failure %
                </p>
              </div>
              <div
                className="dot"
                style={{
                  backgroundColor: determineDotColor(
                    demoMode ? 9.6 : 
                    systemInfo?.[intersection]?.split_failure?.percent_cycles
                  )
                }}
              ></div>
            </div>
          </div>
          <div className="grid-item">
            <div className="content">
              <div>
                <p>
                  {systemInfo
                    ? determinePercentageDisplay(
                      demoMode ? 17.9 : 
                      systemInfo?.[intersection]?.yellow_light_running
                        ?.percent_cycles
                    )
                    : "-"}
                </p>
                <p
                  style={{
                    paddingTop: "4px",
                    fontSize: "12px",
                    color: "#999AAD",
                  }}
                >
                  Yellow Light Running %
                </p>
              </div>
              <div
                className="dot"
                style={{
                  backgroundColor: determineDotColor(
                    demoMode ? 17.9 : 
                    systemInfo?.[intersection]?.yellow_light_running
                      ?.percent_cycles
                  ),
                }}
              ></div>
            </div>
          </div>
          <div className="grid-item">
            <div className="content">
              <div>
                <p>
                  {systemInfo
                    ? determinePercentageDisplay(
                      demoMode ? 0.51 : 
                      systemInfo?.[intersection]?.pedestrian_conflict
                        ?.percent_cycles
                    )
                    : "-"}
                </p>
                <p
                  style={{
                    paddingTop: "4px",
                    fontSize: "12px",
                    color: "#999AAD",
                  }}
                >
                  Ped Conflict %
                </p>
              </div>
              <div
                className="dot"
                style={{
                  backgroundColor: determineDotColor(
                    demoMode ? 0.51 : 
                    systemInfo?.[intersection]?.pedestrian_conflict
                      ?.percent_cycles
                  )
                }}
              ></div>
            </div>
          </div>
        </div>

        <div className="select-metric">
          <button
            className={`${metric === "long_duration" ? "active" : ""}`}
            onClick={() => setMetric("long_duration")}
          >
            LD
          </button>
          <button
            className={`${metric === "rlr" ? "active" : ""}`}
            onClick={() => setMetric("rlr")}
          >
            RLR
          </button>
          <button
            className={`${metric === "ylr" ? "active" : ""}`}
            onClick={() => setMetric("ylr")}
          >
            YLR
          </button>
          <button
            className={`${metric === "gap" ? "active" : ""}`}
            onClick={() => setMetric("gap")}
          >
            GA
          </button>
          <button
            className={`${metric === "split_failure" ? "active" : ""}`}
            onClick={() => setMetric("split_failure")}
          >
            SF
          </button>
          <button
            className={`${metric === "ped" ? "active" : ""}`}
            onClick={() => setMetric("ped")}
          >
            PC
          </button>
        </div>
        <div className="metric-view">
          {metric === "long_duration" && formattedData && videoObj && (
            <LongDuration formattedData={formattedData} videoObj={videoObj} />
          )}
          {metric === "rlr" && formattedData && videoObj && (
            <RLR formattedData={formattedData} videoObj={videoObj} />
          )}
          {metric === "ylr" && formattedData && videoObj && (
            <YLR formattedData={formattedData} videoObj={videoObj} />
          )}
          {metric === "gap" && formattedData && videoObj && (
            <GapAcceptance formattedData={formattedData} videoObj={videoObj} />
          )}
          {metric === "split_failure" && formattedData && videoObj && (
            <SplitFailure formattedData={formattedData} videoObj={videoObj} />
          )}
          {metric === "ped" && formattedData && videoObj && (
            <PedConflict formattedData={formattedData} videoObj={videoObj} />
          )}
        </div>

        {(!formattedData || !videoObj) && !noData && <p>Loading...</p>}
        {noData && !formattedData && <p>No data to display.</p>}
      </div>

    </div>
  );
};

export default Overlay;
