import { useEffect, useState, useCallback } from "react"
import RLRBar from '../graphs/RLRBar'
import VideoPlayer from "../VideoPlayer"

const RLR = ({ formattedData, videoObj }) => {
    const [data, setData] = useState([])
    const [hour, setHour] = useState(null)
    const [noData, setNoData] = useState(true)

    useEffect(() => {
        setNoData(true)

        let allData = []
        for (const key in formattedData) {
            const eventData = formattedData[key];

            if (eventData.eventType.startsWith('rlr')) {
                console.log(eventData.eventType)
                let newData = eventData;
                const videoData = videoObj[eventData.eventType]

                if (videoData) {
                    newData["video_data"] = videoObj[eventData.eventType]
                }
                allData.push(eventData)
                setNoData(false)
            }
        }
        console.log(allData)
        setData(allData)
    }, [formattedData, videoObj])

    const handleBarClick = useCallback((entry) => {
        console.log('Clicked Hour:', entry.hour);
        setHour(entry.hour);
    }, []);

    const handleBackClick = () => {
        setHour(null)
        console.log('Back to graph')
    }

    return (
        <div>
            {hour && <div>
                {videoObj && Object.keys(videoObj).map((property) => {
                    const hoursData = videoObj[property];
                    if (property.startsWith('rlr')) {
                        return (
                            <div key={property}>
                                {Object.keys(hoursData).map((hour_obj) => {
                                    const videoLinks = hoursData[hour_obj];

                                    if (hour === parseInt(hour_obj) - 1) {
                                        return (
                                            <div key={hour_obj}>
                                                {videoLinks.length > 0 && <VideoPlayer videoLinks={videoLinks} backClick={handleBackClick} label={property} hour={hour} />}
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        );
                    }

                })}

            </div>}
            {data && !hour && data.map((dataObj, idx) => {
                const { video_data } = dataObj;

                return (
                    <div key={idx}>
                        <RLRBar label={'RLR'} index={idx} data={dataObj.data} handleBarClick={handleBarClick} />
                    </div>
                );
            })}
            {noData && <div>
                <h3>Controller data needed for this metric.</h3>
            </div>}
        </div>
    );
}

export default RLR